import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a6d953e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "create-driver" }
const _hoisted_2 = { style: {"text-align":"center","margin":"2rem 0"} }
const _hoisted_3 = { class: "item" }
const _hoisted_4 = { class: "error" }
const _hoisted_5 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, "Are you sure you want to delete " + _toDisplayString(_ctx.driver.name) + "?", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.error_msg), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "btn secondary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
      }, "Cancel"),
      _createElementVNode("button", {
        class: "btn primary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteDriver()))
      }, " Delete ")
    ])
  ]))
}