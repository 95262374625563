import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["stat-card", {
      limited_height: _ctx.limited_height,
      max_width: _ctx.max_width,
      flex: _ctx.flex
    }])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}