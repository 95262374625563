
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import Calendar from "primevue/calendar";
import { defineComponent, watchEffect } from "vue";
import { Trip } from "@/types";
import InputNumber from "primevue/inputnumber";
export default defineComponent({
  props: {
    trip: {
      type: Object as () => Trip | undefined,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  components: {
    Dropdown,
       Calendar,
    InputNumber,
    InputSwitch,
  },
  data() {
    return {
      isDirty: false,
      showDangerZone: false,
      tempTrip: {} as Trip,
      tripTypes: [
        "Dry Van",
        "Reefer",
        "Layover",
        "Cancel",
        "Detention",
        "Empty Miles",
        "Other",
      ],
    };
  },
  methods: {
    formatToUSD(value?: number | null) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value ?? 0);
    },
    saveChanges() {
      this.$emit("save-changes", this.tempTrip);
    },
    deleteTrip() {
      this.$emit("delete-trip", this.tempTrip);
    },
    prettifyDate(date: string) {
      return new Date(date).toLocaleDateString();
    },
  },
  mounted() {
    if (this.trip) {
      this.tempTrip = JSON.parse(JSON.stringify(this.trip));
      console.log("AA", this.tempTrip);
    }

    watchEffect(() => {
      //compare tempTrip to trip
      this.isDirty =
        JSON.stringify(this.tempTrip) !== JSON.stringify(this.trip);
    });
  },
});
