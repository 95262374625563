
import { defineComponent } from "vue";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { db, app, auth } from "@/main";
import {
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

export default defineComponent({
  name: "HomeView",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      username: "",
      confirm_password: "",
      stored_auth_code: "",
      loginPage: true,
      registerPage: false,
      resetPage: false,
      error_msg: "" as string,
      loader_visible: false,

      places: [] as any,
    };
  },

  methods: {
    async loadTest() {
      const querySnapshot = await getDocs(collection(db, "users"));

      console.log(querySnapshot);
      console.log("querySnapshot");

      this.places = [] as any;
      querySnapshot.forEach((doc) => {
        let temp = doc.data();
        temp.id = doc.id;
        this.places.push(temp);
      });
    },
    displayLogin() {
      this.loginPage = true;
      this.registerPage = false;
      this.resetPage = false;
    },
    displayRegister() {
      this.loginPage = false;
      this.registerPage = true;
      this.resetPage = false;
    },
    displayReset() {
      this.loginPage = false;
      this.registerPage = false;
      this.resetPage = true;
    },
    async sendPassReset() {
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.displayError("Password reset email has been sent.");
          setTimeout(() => {
            this.displayLogin();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          let temp_err;
          switch (error.message) {
            case "Firebase: Error (auth/invalid-email).":
              temp_err = "Invalid email.";
              break;
            case "Firebase: Error (auth/network-request-failed).":
              temp_err = "It looks like you are offline. Try again later.";
              break;
            case "Firebase: Error (auth/user-not-found).":
              temp_err = "User with this email doesn't exist.";
              break;

            default:
              temp_err = "Something went terribly wrong.";
          }
          this.displayError(temp_err);
        });
    },

    async loginWithEmailAndPass() {
      if (this.password == "") {
        this.error_msg = " The password cannot be empty";
      } else {
        try {
          this.loader_visible = true;
          await signInWithEmailAndPassword(auth, this.email, this.password)
            .then(() => {
              // Signed in

              this.$router.push("/dashboard");
            })
            .catch((error) => {
              let temp_err;
              /* const errorCode = error.code; */
              const errorMessage = error.message;

              console.log(errorMessage);
              switch (error.message) {
                case "Firebase: Error (auth/invalid-email).":
                  temp_err = "Incorrect email or password.";
                  break;
                case "Firebase: Error (auth/wrong-password).":
                  temp_err = "Incorrect email or password.";
                  break;
                case "Firebase: Error (auth/user-not-found).":
                  temp_err = "User with this email doesn't exist.";
                  break;
                case "Firebase: Error (auth/user-disabled).":
                  temp_err = "User with this email has been disabled.";
                  break;
                case "Firebase: Error (auth/too-many-requests).":
                  temp_err = "Too many requests. Try again later.";
                  break;
                case "Firebase: Error (auth/operation-not-allowed).":
                  temp_err =
                    "Signing in with Email and Password is not enabled.";
                  break;
                case "Firebase: Error (auth/network-request-failed).":
                  temp_err = "It looks like you are offline. Try again later.";
                  break;
                default:
                  temp_err = "Something went terribly wrong.";
              }
              this.displayError(temp_err);
              this.loader_visible = false;
            });
        } catch (error) {
          console.log(error);
        }
      }
    },

    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      try {
        await signInWithPopup(auth, provider);

        this.$router.push("/dashboard");
      } catch (error) {
        console.log(error);
      }
    },

    displayError(err: any) {
      this.error_msg = err;
      this.error_msg = "Error displaying is not working";
      /* setTimeout(
        function () {
          this.error_msg = "";
        }.bind(this),
        3000
      ); */
    },

    async submitRegisterForm() {
      if (this.confirm_password != this.password) {
        this.error_msg = "Passwords do not match.";
      } else if (
        this.confirm_password == "" ||
        this.password == "" ||
        this.username == ""
      ) {
        this.displayError("Fields cannot be empty.");
      } else {
        try {
          await createUserWithEmailAndPassword(auth, this.email, this.password);

          if (auth.currentUser) {
            await updateProfile(auth.currentUser, {
              displayName: this.username,
            });
          }
          this.$router.push("/dashboard");
        } catch (error: any) {
          let temp_err;
          console.log(error.message);
          console.log("error.message");
          switch (error.message) {
            case "Firebase: Error (auth/email-already-in-use).":
              temp_err =
                'This email is already registered. If you forgot your password click on "Forgot Password?"';
              break;
            case "Firebase: Error (auth/invalid-email).":
              temp_err = "Invalid email.";
              break;
            case "Firebase: Error (auth/operation-not-allowed).":
              temp_err = "Email/Password sign-in is currently disabled.";
              break;
            case "Firebase: Error (auth/weak-password).":
              temp_err = "Your password is too weak.";
              break;
            case "Firebase: Error (auth/network-request-failed).":
              temp_err = "It looks like you are offline. Try again later.";
              break;
            default:
              temp_err = "Something went terribly wrong.";
          }
          this.displayError(temp_err);
        }
      }
    },
  },
});
