import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-592455ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stat-card" }
const _hoisted_2 = { class: "right" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
      (!_ctx.options || _ctx.options.length <= 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Looks like you haven't created any drivers yet. Create a driver to get started. "))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.text), 1))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.options && _ctx.options.length > 0)
        ? (_openBlock(), _createBlock(_component_Dropdown, {
            key: 0,
            modelValue: _ctx.selectedDriver,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDriver) = $event)),
            options: _ctx.options,
            optionLabel: "name",
            placeholder: "Select a driver",
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectDriver(_ctx.selectedDriver)))
          }, null, 8, ["modelValue", "options"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}