
import { defineComponent } from "vue";
import { auth } from "@/main";
export default defineComponent({
  setup() {
    return {};
  },
  computed: {
    user_url(): string {
      if (auth && auth.currentUser) {
        return `${auth.currentUser.photoURL}`;
      }
      return "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
    },
  },
});
