import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.display_header)
      ? (_openBlock(), _createBlock(_component_HeaderComponent, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.display_header)
      ? (_openBlock(), _createBlock(_component_SidebarComponent, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}