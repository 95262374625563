import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { MotionPlugin } from "@vueuse/motion";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import  { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"; 

import PrimeVue from 'primevue/config';
declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean;
  }
}


const firebaseConfig = {
  apiKey: "AIzaSyBn4eHEkpu2NcLCwQ3mbK_ZN0xgPRIC0H4",
  authDomain: "benefittrans-f20ac.firebaseapp.com",
  projectId: "benefittrans-f20ac",
  storageBucket: "benefittrans-f20ac.appspot.com",
  messagingSenderId: "561893097408",
  appId: "1:561893097408:web:4e3c1b50ed1bb2f0105225",
  measurementId: "G-0P5T2TLX5L"
};
if (process.env.NODE_ENV == 'development') {

  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

}
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfWlIcfAAAAAHvbal2pyaXHH9jD2MH-7GvtPvGU'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});


let vue_app: any;




onAuthStateChanged(auth, () => {
  if (!vue_app) {
    vue_app = createApp(App)
      .use(store)
      .use(MotionPlugin)
      .use(router)
      .use(PrimeVue)
      .mount("#app");
  }
});

export { app, db, analytics, functions, auth, appCheck };
