import { Driver } from "@/types";
import { createStore } from "vuex";
export type State = {
  user: any;
  mobile_menu_visible: boolean;
  trip_calculator_visible: boolean;
  edit_trip_dialog_visible: boolean;
  selected_driver: Driver | null;
  drivers: Driver[] | null;
};

export default createStore({
  state: {
    user: null,
    mobile_menu_visible: false,
    trip_calculator_visible: false,
    selected_driver: null as Driver | null,
    drivers: null as Driver[] | null,
    needs_refresh_drivers: false,
    edit_trip_dialog_visible: false,
  },
  getters: {},
  mutations: {
    toggleMobileMenuVisible(state) {
      state.mobile_menu_visible = !state.mobile_menu_visible;
    },
    setSidebarVisible(state, visible) {
      state.mobile_menu_visible = visible;
    },

    //set user
    setUser(state, user) {
      state.user = user;
    },

    //set selected driver setSelectedDriver
    setSelectedDriver(state, driver) {
      state.selected_driver = driver;
      state.needs_refresh_drivers = true;
      localStorage.setItem("selectedDriverId", driver.id);
    },
    loadSelectedDriver(state) {
      const savedDriverId = localStorage.getItem("selectedDriverId");
      if (savedDriverId) {
        const driver = state.drivers?.find((d) => d.id === savedDriverId);
        if (driver) {
          state.selected_driver = driver;
        }
      }
    },

    //set trip calculator visible
    setTripCalculatorVisible(state, visible) {
      state.trip_calculator_visible = visible;
    },

    //set needs refresh drivers
    setNeedsRefreshDrivers(state, refresh) {
      state.needs_refresh_drivers = refresh;
    },

    //set drivers\
    setDrivers(state, drivers) {
      state.drivers = drivers;
    },

    //set driver trips by driver id
    setDriverTrips(state, payload) {
      state.drivers?.forEach((driver) => {
        if (driver.id === payload.driver_id) {
          driver.trips = payload.trips;
        }
      });
    },

    pushToDrivers(state, driver) {
      state.drivers?.push(driver);
    },

    //edit_trip_dialog_visible
    setEditTripDialogVisible(state, visible) {
      state.edit_trip_dialog_visible = visible;
    },
  },
  actions: {
    toggleMobileMenuVisible({ commit }) {
      commit("toggleMobileMenuVisible");
    },
    setSidebarVisible({ commit }, visible) {
      commit("setSidebarVisible", visible);
    },
    //set user
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    //set selected driver
    setSelectedDriver({ commit }, driver) {
      commit("setSelectedDriver", driver);
    },

    //set trip calculator visible
    setTripCalculatorVisible({ commit }, visible) {
      commit("setTripCalculatorVisible", visible);
    },

    //set needs refresh drivers
    setNeedsRefreshDrivers({ commit }, refresh) {
      commit("setNeedsRefreshDrivers", refresh);
    },

    //set drivers
    setDrivers({ commit }, drivers) {
      commit("setDrivers", drivers);
    },

    //set driver trips by driver id
    setDriverTrips({ commit }, payload) {
      commit("setDriverTrips", payload);
    },

    pushToDrivers({ commit }, driver) {
      commit("pushToDrivers", driver);
    },

    //edit_trip_dialog_visible
    setEditTripDialogVisible({ commit }, visible) {
      commit("setEditTripDialogVisible", visible);
    },
  },
  modules: {},
});
