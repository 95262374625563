
import { defineComponent } from "vue";
import { auth } from "@/main";
export default defineComponent({
  name: "SidebarComponent",
  components: {},
  data() {
    return {
      notification_opened: false,
      menu_opened: false,
    };
  },
  methods: {
    displayNotification() {
      this.notification_opened = !this.notification_opened;
    },
    async signOut() {
      auth
        .signOut()
        .then(() => {
          localStorage.clear();
          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
