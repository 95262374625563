import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LandingPageView from "../views/LandingPageView.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import DashboardView from "../views/DashboardView.vue";
import PayrollView from "../views/PayrollView.vue";
import DriversView from "../views/DriversView.vue";

import LoginPageView from "../views/LoginPageView.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: "/payroll",
    name: "payroll",
    component: PayrollView,
    meta: { requiresAuth: true },
  },
  {
    path: "/drivers",
    name: "drivers",
    component: DriversView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPageView,
  },
  {
    path: '/:pathMatch(.*)*', // This will catch all navigation that doesn't match.
    redirect: '/login',
  },
  

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (requiresAuth && !user) {
      next('/login');
    } else {
      next();
    }
  });
});

export default router;
