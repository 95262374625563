
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmptyDashboardStatCard",
  props: {
    limited_height: {
      type: Boolean,
    },
    max_width: {
      type: Boolean,
    },
    flex: {
      type: Boolean,
      default: true,
    },
  },

});
